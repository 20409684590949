import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {DirectoryService} from '../../../file-management/services/directory.service';
import {BreadcrumbService, MessageService} from 'rb-core-library';
import {Subscription} from 'rxjs';
import {User} from '../../models/user';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'bi-landingzone';
    intro = '';
    currentUser: User;
    directories = [];
    currentUserSubscription: Subscription;
    pageInfoSubscription: Subscription;

    constructor(private authService: AuthService, private directoryService: DirectoryService, public messageService: MessageService,
                private breadcrumbService: BreadcrumbService) {
    }

    ngOnInit(): void {
        this.pageInfoSubscription = this.breadcrumbService.customInfoChanged().subscribe((pageInfo) => {
            setTimeout(() => {
                this.title = pageInfo.title;
                this.intro = pageInfo.intro;
            });
        });

        this.currentUserSubscription = this.authService.currentUser$.subscribe(currentUser => {
            setTimeout(() => {
                this.currentUser = currentUser;
                if (currentUser) {
                    this.directoryService.getAvailableDirectories().subscribe(directories => {
                        this.directories = [...directories.personal, ...directories.shared];
                    });
                }
            });
        });
    }

    public ngOnDestroy(): void {
        if (this.pageInfoSubscription) {
            this.pageInfoSubscription.unsubscribe();
        }
    }


}
