export const defaultEnvironment = {
    redBullLoginUrl: 'https://logon.redbull.com/pkmsvouchfor?wiiings&https://workbench.redbull.com/validateToken/',
    production: false,
    personalDirectories: {
        'personal': 'Personal'
    },
    sharedDirectories: {
        'shared1': 'Shared1',
        'shared2': 'Shared2'
    }
};
