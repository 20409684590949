import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LocationService {
    loadUrl(url: string) {
        window.location.assign(url);
    }

    getCurrentOrigin(): string {
        return window.location.origin;
    }

    getCurrentUrl(): string {
        return window.location.href;
    }
}
