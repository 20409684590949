import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DirectoryItem} from '../models/directory-item';
import {Page} from '../../core/models/page';
import {Observable, throwError} from 'rxjs';
import {DirectoriesList} from '../models/directories-list';
import {UploadLinkResponse} from '../models/upload-link-response';
import {switchMap, timeout} from 'rxjs/operators';
import {AuthService} from '../../core/services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class DirectoryService {
    constructor(private http: HttpClient, private authService: AuthService) {
    }

    public getDirectoryPathContents(directory: string, path: string): Observable<Page<DirectoryItem>> {
        const {imsId} = this.authService.currentUser;
        const url = `/api/directories/${directory}/paths/${encodeURIComponent(this.correctPath(path))}`;

        return this.http.get<Page<DirectoryItem>>(url, {
            params: {user: imsId}
        });
    }

    public getAvailableDirectories(): Observable<DirectoriesList> {
        return this.http.get<DirectoriesList>(`/api/directories`);
    }

    public createNewPath(directory: string, path: string): Observable<void> {
        const {imsId} = this.authService.currentUser;

        return this.http.post<void>(`/api/directories/${directory}/paths`, {
            user: imsId,
            path: path
        });
    }

    public uploadFile(directory: string, path: string, file: File): Observable<void> {
        const {imsId} = this.authService.currentUser;

        return this.createUploadLink(directory, path, file.name, imsId).pipe(
            switchMap(({uploadLink}) => {
                if (uploadLink) {
                    return this.putFileToUploadLink(file, uploadLink);
                } else {
                    console.error('No upload link was received!');
                    throwError('No upload link was received!');
                }
            })
        );
    }

    private putFileToUploadLink(file: File, uploadLink: string): Observable<void> {
        return this.http.put<void>(uploadLink, file, {
            headers: {
                'Content-Type': file.type
            }
        }).pipe(timeout(60000));
    }

    private createUploadLink(directory: string, path: string, fileName: string, user?: string): Observable<UploadLinkResponse> {
        return this.http.post<UploadLinkResponse>(`/api/directories/${directory}/files`, {
            file: `${path}/${fileName}`,
            user: user
        });
    }

    private correctPath(path: string): string {
        return (path === '') ? '/' : path;
    }

    public deleteFile(directory: string, file: string): Observable<void> {
        const {imsId} = this.authService.currentUser;

        return this.http.delete<void>(`/api/directories/${directory}/files/${encodeURIComponent(file)}`, {
            params: {user: imsId}
        });
    }

    public deletePath(directory: string, path: string): Observable<void> {
        const {imsId} = this.authService.currentUser;

        return this.http.delete<void>(`/api/directories/${directory}/paths/${encodeURIComponent(path)}`, {
            params: {user: imsId}
        });
    }

    public getDownloadLink(directory: string, file: string): string {
        const {imsId} = this.authService.currentUser;

        return `/api/directories/${directory}/files/${encodeURIComponent(file)}?user=${imsId}`;
    }
}
