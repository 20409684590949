import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './core/components/app/app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';
import {
    BasicHeaderModule,
    BreadcrumbModule,
    LoadingSpinnerModule,
    MessageModule,
    PageHeaderModule
} from 'rb-core-library';
import {CommonModule} from '@angular/common';
import {NoCacheInterceptor} from './core/http-interceptors/no-cache.interceptor';
import {AuthService} from './core/services/auth.service';
import {AuthGuardService} from './core/services/auth-guard.service';
import {NotAuthorizedInterceptor} from './core/interceptors/not-authorized.interceptor';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LoginPageComponent} from './core/components/login-page/login-page.component';

@NgModule({
    declarations: [
        AppComponent,
        LoginPageComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        BreadcrumbModule,
        AppRoutingModule,
        MessageModule,
        BasicHeaderModule,
        PageHeaderModule,
        LoadingSpinnerModule
    ],
    providers: [
        CookieService,
        AuthService,
        AuthGuardService,
        {provide: HTTP_INTERCEPTORS, useClass: NoCacheInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: NotAuthorizedInterceptor, multi: true}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
