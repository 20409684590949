import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginResult} from '../../models/login-result';

@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
    public errorOccurred = false;
    private redirectUrl: string;

    constructor(private route: ActivatedRoute, private router: Router, private authService: AuthService) {
    }

    public async ngOnInit(): Promise<void> {
        this.redirectUrl = this.route.snapshot.queryParams['redirectUrl'] || '/';
        this.authService.resetAuthenticationStatus();

        await this.login();
    }

    public async login() {
        this.errorOccurred = false;
        try {
            const loginResult = await this.authService.login(this.redirectUrl);
            if (loginResult === LoginResult.SUCCESS) {
                this.router.navigateByUrl(this.redirectUrl);
            }
        } catch (error) {
            this.errorOccurred = true;

        }
    }
}
