import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AuthService} from '../services/auth.service';

@Injectable()
export class NotAuthorizedInterceptor implements HttpInterceptor {

    constructor(private router: Router) {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap(null, error => {
                if (req.url !== AuthService.AUTH_URL) {
                    const httpError: HttpErrorResponse = error;
                    if (httpError.status === 401 || httpError.status === 403) {
                        this.router.navigate(['login'], {queryParams: {redirectUrl: this.router.url}})
                    }
                }
            })
        );
    }
}
