import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {User} from '../models/user';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {EnvironmentService} from './environment.service';
import {LocationService} from './location.service';
import {LoginResult} from '../models/login-result';

@Injectable()
export class AuthService {
    public static readonly AUTH_URL = '/api/me';
    currentValidatedCookie: string = undefined;

    constructor(private cookieService: CookieService, private http: HttpClient, private environmentService: EnvironmentService,
                private locationService: LocationService) {
    }

    private _currentUser: BehaviorSubject<User> = new BehaviorSubject(undefined);

    get currentUser(): User {
        return this._currentUser.getValue();
    }

    set currentUser(user: User) {
        this._currentUser.next(user);
    }

    get currentUser$(): Observable<User> {
        return this._currentUser.asObservable();
    }

    get isAuthenticated(): boolean {
        if (!this.cookieService.check('BIGip_APM_VIE_P')) {
            return false;
        }

        return this.currentValidatedCookie === this.cookieService.get('BIGip_APM_VIE_P');
    }

    public async login(redirectUrl: string): Promise<LoginResult> {
        try {
            this.currentUser = await this.http.get<User>(AuthService.AUTH_URL).toPromise();
            this.currentValidatedCookie = this.cookieService.get('BIGip_APM_VIE_P');
            return Promise.resolve(LoginResult.SUCCESS);
        } catch (error) {
            console.error(`Error while fetching the current user: ${JSON.stringify(error)}.`);
            const httpError: HttpErrorResponse = error;

            if (httpError.status === 401 || httpError.status === 403) {
                const completeRedirectUrl = this.locationService.getCurrentOrigin() + redirectUrl;
                const loginUrl = `${this.environmentService.get('redBullLoginUrl')}?origin=${encodeURIComponent(completeRedirectUrl)}&apm=1`;
                this.locationService.loadUrl(loginUrl);
                return Promise.resolve(LoginResult.REDIRECT);
            }

            return Promise.reject(error);
        }
    }

    public resetAuthenticationStatus() {
        this.currentUser = undefined;
        this.currentValidatedCookie = undefined;
    }
}
