import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuardService} from './core/services/auth-guard.service';
import {LoginPageComponent} from './core/components/login-page/login-page.component';

const routes: Routes = [{
    path: 'login',
    component: LoginPageComponent
}, {
    path: 'buckets',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./file-management/file-management.module').then(mod => mod.FileManagementModule)
}, {
    path: '**',
    redirectTo: 'buckets'
}];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
